import * as React from 'react';
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {allCountries} from "../utils/geodata";

export const CountrySelector = ({countryCode, disabled, margin, required, setCountryCode, ...props}) => {
    const selectedCountry = allCountries.find((country) => country.abbr === countryCode);
    const handleChange = (event, value) => {
        // It's not a proper Select element so we're not using event.target.value
        if (value === null) {
            setCountryCode(false);
        } else {
            setCountryCode(value.abbr);
        }
    };
    React.useEffect(() => {
        // If country not valid set default
        !selectedCountry && setCountryCode(allCountries.find((country) => country.abbr === `US`));
        // TODO: Check geo and set default instead of USA
    }, [selectedCountry, setCountryCode]);

    const getOptionLabel = (opt) => opt.name;
    const textfieldMargin = margin || `normal`;

    return (
        <Autocomplete
            autoComplete
            autoSelect
            disableClearable
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            id="country-selector"
            onChange={handleChange}
            options={allCountries}
            renderInput={params => (
                <TextField {...params} fullWidth label="Country" margin={textfieldMargin} variant="outlined" />
            )}
            required={required}
            style={{ minWidth: 200 }}
            value={selectedCountry}
            {...props}
        />
    );
};

CountrySelector.propTypes = {
    countryCode: PropTypes.string,
    margin: PropTypes.oneOf([`none`,`dense`,`normal`]),
    required: PropTypes.bool,
    setCountryCode: PropTypes.func.isRequired,
};
