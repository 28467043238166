import * as React from 'react';
import useCallbackStatus from "../../utils/use-callback-status";
import {Button, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../../context/authContext";
import { useDispatch } from 'react-redux'
import {addMessage} from "../alerts/messagesSlice";
import { v1 as uuidv1 } from 'uuid';
import {navigate} from "gatsby";
import {useUIDispatch} from "../../app/uiContext";

const useStyles = makeStyles(theme => ({
    form: {
        width: `100%`, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

// TODO: Move error messages to higher level language agnostic component
const getMessage = (err) => {
    switch (err.code) {
        case 100:
            return (`Invalid user credentials`);
        case 114:
            return (`Please enter a valid email address`);
        default:
            return (err.message);
    }
};
// TODO: Grey-out or add spinner to loginform button
export const LoginForm = ({ email, redirect, setEmail }) => {
    const uiDispatch = useUIDispatch();
    const dispatch = useDispatch();
    const {login} = useAuth();
    const classes = useStyles();
    const [emailError, setEmailError] = React.useState(``);
    const [password, setPassword] = React.useState(``);
    const {isPending, isRejected, isSuccess, error, run} = useCallbackStatus();
    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    };
    function handleSubmit(event) {
        event.preventDefault();
        const {email, password} = event.target.elements;
        run(
            login({
                email: email.value,
                password: password.value,
            }).then(() => {
                if (isSuccess) {
                    redirect ? navigate(redirect) : uiDispatch({type:`HIDE_LOGIN_REGISTER`})
                }}),
        )
    }

    React.useEffect(() => {
        if (isRejected && error.code) {
            dispatch(addMessage({id:uuidv1(), text:getMessage(error), priority: `low`}));
        }
    }, [error, dispatch, isRejected]);

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
                autoComplete="email"
                defaultValue={email}
                error={emailError !== ``}
                fullWidth
                helperText={emailError}
                id="email"
                label="Email Address"
                margin="normal"
                name="email"
                onChange={e => {setEmail(e.target.value);setEmailError(``)}}
                required
                variant="outlined"
            />
            <TextField
                autoComplete="current-password"
                defaultValue={password}
                fullWidth
                id="password"
                label="Password"
                margin="normal"
                name="password"
                onChange={e => {setPassword(e.target.value)}}
                required
                type="password"
                variant="outlined"
            />
            <Button
                className={classes.submit}
                color="primary"
                disabled={isPending || !validateForm()}
                fullWidth
                type="submit"
                variant="contained"
            >
                Log In {isPending ? null : null}
            </Button>
        </form>
    )
};
