import * as React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Link} from "gatsby";

export const AcceptTerms = ({checked, handleChecked}) => {
    return (
        <FormControlLabel
            className="mb-4"
            control={<Checkbox
                checked={checked}
                inputProps={{ 'aria-label': `primary checkbox` }}
                onChange={handleChecked}
                required
            />}
            label={<span>I agree to <Link className="text-primary hover:text-primary-light" to="/terms">terms and conditions</Link></span>}
        />
    )
}
