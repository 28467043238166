import * as React from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export function SEO({ description, lang = `en`, meta = [], keywords = [], title }) {
    const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          logoUrlPng
        }
      }
    }
  `);

    const metaDescription = description || data.site.siteMetadata.description;
    const fullTitle = `${title} | ${data.site.siteMetadata.title}`;

    return (
        <Helmet>
            {/* General tags */}
            <html lang={lang} />
            <meta charSet="utf-8" />
            <title>{fullTitle}</title>
            <meta content={metaDescription} name="description" />
            <meta content={data.site.siteMetadata.logoUrlPng} name="image" />
            {keywords.length ? <meta content={keywords.join(`, `)} name="keywords" /> : null}

            {/* OpenGraph tags */}
            <meta content="website" property="og:type" />
            <meta content={fullTitle} property="og:title" />
            <meta content={metaDescription} property="og:description" />
            <meta content={data.site.siteMetadata.logoUrlPng} property="og:image" />

            {/* Twitter Card tags */}
            <meta content="summary_large_image" name="twitter:card" />
            <meta content={data.site.siteMetadata.author} name="twitter:creator" />
            <meta content={fullTitle} name="twitter:title" />
            <meta content={metaDescription} name="twitter:description" />
            <meta content={data.site.siteMetadata.logoUrlPng} name="twitter:image" />

            {/* Performance related */}
            <link crossOrigin="true" href="https://api.ccgtrader.co.uk/" rel="preconnect" />
            <link href="https://api.ccgtrader.co.uk/" rel="dns-prefetch" />
        </Helmet>
    );
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired
};
